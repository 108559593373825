<template>
  <div>
    <bayWindow v-if="baywindowsimage" :image="baywindowsimage" :width="217" :height="112" :bayid="baywindowsid"
      :bayzd="baywindowszd" :baytype="baywindowstype"></bayWindow>
    <div class="header"></div>
    <div class="personcenter">
      <div style="display: flex; margin-left: 20px">
        <div @click="login" v-if="!islogin">
          <i class="el-icon-user"></i>
          登录
        </div>
        <div @click="Register" style="margin-left: 20px" v-if="!islogin">
          <i class="el-icon-edit-outline"></i>
          注册
        </div>
      </div>
      <div v-if="islogin">
        <li class="UserSpacehover">
          <i class="el-icon-user"></i>用户空间
          <ul class="UserSpace">
            <li>
              <a @click="navigationSelect()">个人中心</a>
            </li>
            <li><a href="#" @click="Logout">退出</a></li>
          </ul>
        </li>
      </div>
    </div>
    <div class="remen">
      <div class="global-search">
        <div class="global-search__input el-input el-input--prefix el-input--suffix">
          <input type="text" autocomplete="off" style="padding-left: 80px; outline: none; border: none"
            placeholder="请输入关键词" class="el-input__inner" v-model="searchValue" />
          <span class="el-input__prefix">
            <select v-model="value" placeholder="请选择" style="
                width: 78px;
                border-radius: 20px 0px 0px 20px;
                font-size: 15px;
                height: 1.9rem;
                outline: none;
                border: none;
                text-align: left;
                cursor: pointer;
              ">
              <option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                style="text-align: center"></option>
            </select>
          </span>
          <div class="global-search__btn" @click="doSearch">
            <i class="icon-search"></i>
          </div>
        </div>
      </div>
      <!-- <div class="home-page__top__hotWords">
        <span class="hot-words-label"><img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAcCAYAAACK7SRjAAABhUlEQVRIibXWv0tVYRzH8dc5t0tgmUMtWpT9wEETLuEkOCRILkJF1F4EDTkmDhEETWX0DyROLuLiJPhjcmor7xCIW4TU2iIV3Ybjqcvhes7x3sfP9P3xfN9fnvN8n3NO1FjuVqA5rGKzaGFWcYk1Vazj5XHA9xDhBaZDw/eb7LcYCgnvabKreBMSfjbjT2IgFPxSxo/wIBR8pEXsVgj4MPpaxGuodAq/e0j8lBLPPQ9+Ag9z8sOdwO/jYk6+v134SbwqqM1rnAt/jssFtdkRLQUfxWxRYYnm1Sz8ApYkh1mkfofv/DTeNSe7sILzJcAk43i9RXwAWxhM4REWcaMkONXTg1q4JnmpfZRcsp10+9O4fUQwPMYUGujN5D5FjeXuPnzGmTbgearFeHIM4C+ox7gXGExyfn9iXAkM/o0FkjndCwx/j50UPh8QXMdM6sR4Lbk8neoDJvCjGf4Td/AIX9uAfsMzjB3Y/xRlfucquIlxybfzKs75P6r7+I5dbGMNG/jVqutfv9Q4CXUli+4AAAAASUVORK5CYII=" />热门：</span>
        <div class="hot-words-list">
          <div class="hot-words-item pointer">高质量发展</div>
          <div class="hot-words-item pointer">智能化改造和数字化转型</div>
          <div class="hot-words-item pointer">领军人才</div>
          <div class="hot-words-item pointer">科技创新</div>
        </div>
      </div> -->
    </div>

    <div class="content-banner">
      <div @click="PolicyCalculation">
        <img src="../images/index/PolicyCalculation.png" />
      </div>
      <div @click="PolicyCalculation">
        <img src="../images/index/PolicyCalculationicon.png" />
      </div>
      <div @click="PolicySearch">
        <img src="../images/index/PolicySearch.png" />
      </div>
      <div @click="PolicyInterpretation">
        <img src="../images/index/PolicyInterpretation.png" />
      </div>
      <div @click="freeToEnjoy">
        <img src="../images/index/freeToEnjoy.png" />
      </div>
      <div @click="projectApply">
        <img src="../images/index/projectApply.png" />
      </div>
      <!-- <div class="home-page__nav">
        <div class="nav-lf" @click="PolicyCalculation">
          <div class="lf-icon-bg"></div>
          政策计算<span class="lighter">智能匹配 精准推送</span>
        </div>
        <div class="nav-menus">
          <div class="menu-item" @click="PolicyCalculation">
            <div class="round" style="background-color: #f6af3f">
              <div class="circle" style="background-color: #f6af3f"></div>
              <div class="menu-item__icon menu1"></div>
            </div>
            <div class="menu-item__title" style="color: #f6af3f">政策精算</div>
          </div>
          <div class="menu-item" @click="PolicySearch">
            <div class="round" style="background-color: #00bfd7">
              <div class="circle" style="background-color: #00bfd7"></div>
              <div class="menu-item__icon" style="
                  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAB8CAYAAADpXacpAAAAAXNSR0IArs4c6QAAA2xJREFUeF7t3buKFUEQBuC/EAMN1EAQVgQFU30RX8JHMPAJTAyMBDE3MTI1FQMTU1/AS2AkokaCQckcdsVlXad6qvvvOdP/xHXp/qZ2znXnGHRQBIzSRU3QHNrdbwF4BeDyiN5mtjNuCj068g64NbS73wTwBsCVESf5aM9NoQ+RXwO4OjJy04kW8vHRajLRQj7591sdWsj/vkhWhRby6Y9E1aCF/P+H+yrQQp5/TpWGboT8HMCj+eV3jzgA8DKyihR0I+Rp3Y/N7F5kAz1j3P06gPeRNSyGbogs6KMz1xhZ0JMAAVnQJOSxoYnI40KTkceE7oA8HnQn5LGgOyKPA90ZeQzoFSBvH3olyNuGXhHy5qE/AbgWeSeKELPdd+/c3QmA0RaCjkol4wSdBIymCzoqlYwTdBIwmi7oqFQyTtBJwGi6oKNSyThBJwGj6YIG8BbAz6jYwrgXZvZkYS4tbdH3OgpeGd4wsw+03ay4kaBJJ0fQgiYJkNpoogVNEiC10UQLmiRAaqOJFjRJgNSm+0S7+1MA50n7XdLmi5ndX5L4d84aoL8BuJjdSMP8j2Y2/f9J6hD0PJ+g542qRAi6CuN8EUHPG1WJEHQVxvkigp43qhKxGeiHAM5VIWlT5KuZPciW7v70LruBfckXNOlMCVrQJAFSG020oEkCpDaaaEGTBEhtNNGjQLv7HQBnSfud2vwys9DdumquqftEuzv7E5bvZnapJmKklqAjShViBF0BMVJC0BGlCjGCroAYKSHoiFKFGEFXQIyUWAP0OwAXIoutFPPDzG5XqhUu0x06vNI9DxQ06QQKWtAkAVIbTbSgSQKkNppoQZMESG000aNAH/5Q5BnSflu1mb6fN9108dSj+0R3+ISlBfYzM7sr6Ba0x2sKur3xroOgBX0ooGv0yVH481tZNe+pJGhBl1x1dI0u0UrErh86sbm9Su3+gmWvtBKLFXQCryRV0CVaiVhBJ/BKUgVdopWIFXQCryRV0CVaiVhBJ/BKUgVdopWIFXQCryRV0CVaiVhBJ/BKUgVdopWIFXQCryRV0CVaiVhBJ/BKUgVdopWIFXQCryRV0CVaidjW0NMtIj4n1rel1AMAodtXLPlex5agaHsRNIla0IImCZDaaKIFTRIgtdFEC5okQGqjiRY0SYDU5miifwO0SGm5YLi/kAAAAABJRU5ErkJggg==);
                "></div>
            </div>
            <div class="menu-item__title">政策检索</div>
          </div>
          <div class="menu-item" @click="PolicyInterpretation">
            <div class="round" style="background-color: #67c9bb">
              <div class="circle" style="background-color: #67c9bb"></div>
              <div class="menu-item__icon menu3"></div>
            </div>
            <div class="menu-item__title">政策解读</div>
          </div>
          <div class="menu-item spec" @click="freeToEnjoy">
            <div class="round" style="background-color: #92cf3f">
              <div class="circle" style="background-color: #92cf3f"></div>
              <div class="menu-item__icon menu4"></div>
            </div>
            <div class="menu-item__title">免申即享</div>
          </div>
        </div>
        <div class="nav-rt" @click="freeToEnjoy">
          <div class="rt-icon-bg"></div>
          项目申报<span class="lighter">网申捷享 免申即享</span>
        </div>
    </div> -->
    </div>
    <!-- <div class="content-content">
      <div class="home-page__menus">
        <div class="menu-item" @click="DirectDemand">
          <div class="menu-item__bg itembg1">
            <div class="menu-item__icon-bg itembg1_1"></div>
            <div class="menu-item__title">
              <span class="title">诉求直通</span>
              <span class="sub">多方协调 兜底解决</span>
            </div>
          </div>
        </div>
        <div class="menu-item" @click="activity">
          <div class="menu-item__bg itembg2">
            <div class="menu-item__icon-bg itembg2_2"></div>
            <div class="menu-item__title">
              <span class="title">活动路演</span>
              <span class="sub">多维模式 立体培训</span>
            </div>
          </div>
        </div>
        <div class="menu-item" @click="DigitalMap">
          <div class="menu-item__bg itembg3">
            <div class="menu-item__icon-bg itembg3_3"></div>
            <div class="menu-item__title">
              <span class="title">数字地图</span>
              <span class="sub">开放平台 一站对接</span>
            </div>
          </div>
        </div>
        <div class="menu-item" @click="ProfessionalServices">
          <div class="menu-item__bg itembg4">
            <div class="menu-item__icon-bg itembg4_4"></div>
            <div class="menu-item__title">
              <span class="title">专业服务</span>
              <span class="sub">全面资源 全程服务</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="lf">
      <div class="lf-head">
        <div class="lf-content-image">
          <div @click="DirectDemand">
            <img src="../images/index/DirectDemand.png" alt="" srcset="">
          </div>
          <div @click="DigitalMap">
            <img src="../images/index/DigitalMap.png" alt="" srcset="">
          </div>
          <div @click="activity">
            <img src="../images/index/activity.png" alt="" srcset="">
          </div>
          <div @click="ProfessionalServices">
            <img src="../images/index/ProfessionalServices.png" alt="" srcset="">
          </div>
        </div>
        <div class="el-tabs el-tabs--top" style="width: 680px;">
          <div class="el-tabs__header is-top">
            <div class="el-tabs__nav-wrap is-top">
              <div class="el-tabs__nav-scroll">
                <div role="tablist" class="el-tabs__nav is-top" style="transform: translateX(-0px)">
                  <div id="tab-1" aria-controls="pane-1" role="tab" tabindex="-1" class="el-tabs__item indexlist"
                    :class="isactive" @click="DeclarationInformations">
                    申报信息
                  </div>
                  <div id="tab-2" aria-controls="pane-2" role="tab" tabindex="-1" class="el-tabs__item indexlist"
                    :class="isactive1" @click="ActivityInformations">
                    活动信息
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="le_content" v-if="contentshow">
              <el-table :data="DeclarationInformation.tableData" border style="width: 100%"
                @sort-change="changeTableSort">
                <el-table-column fixed label="标题" width="200">
                  <template #default="scope">
                    <span class="digitaDetails" style="cursor: pointer;" @click="signUp(scope.row.Id)"
                      :title="scope.row.Title">{{ scope.row.Title }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="PiPeiDu" label="匹配度" width="120" sortable="custom">
                </el-table-column>
                <el-table-column prop="Sort" label="级别" width="100">
                </el-table-column>
                <el-table-column prop="Addtime" label="发布日期" width="110" sortable="custom">
                </el-table-column>
                <el-table-column prop="RemainderDay" label="申报截止时间" sortable="custom">
                </el-table-column>
              </el-table>
              <div style="
                  width: 950px;
                  margin: 20px auto;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: left;
                ">
                <el-pagination background layout="prev, pager, next" :pageSize="pageSize"
                  :total="DeclarationInformation.total" @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </div>
          <div>
            <div class="le_content" v-if="activitycontentshow">
              <el-table :data="ActivityInformation.tableData" border style="width: 100%"
                @sort-change="changeActivityTableSort">
                <el-table-column fixed label="活动名称" width="190">
                  <template #default="scope">
                    <span class="digitaDetails" style="cursor: pointer;"
                      @click="activityInfo(scope.row.id, currentStatus[scope.$index])" :title="scope.row.title">{{
                        scope.row.title }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="organizers" label="举办方" width="120">
                </el-table-column>
                <el-table-column prop="address" label="活动地址">
                </el-table-column>
                <el-table-column prop="begin_time" label="活动时间" sortable="custom">
                </el-table-column>
                <el-table-column prop="activityend_time" label="报名截止时间" width="160" sortable="custom">
                </el-table-column>
                <!-- <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">活动详情</el-button>
                  </template>
                </el-table-column> -->
              </el-table>
              <div style="
                  width: 950px;
                  margin: 20px auto;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-align: left;
                ">
                <el-pagination background layout="prev, pager, next" :pageSize="pageSize"
                  :total="ActivityInformation.total" @current-change="handleCurrentChangeactivity">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="lf-content-image">
          <div @click="SpecializedAndNew">
            <div class="about3">专精特新</div>
          </div>
          <div>
            <div class="about"  @click="thousands">千人千企</div>
            <!-- <div style="position: absolute;margin-left: 160px;margin-top: -90px;" v-if="thousandsshow">
              <div @click="thousands" style="background-color: #5E88C6;color: #ffffff;cursor: pointer;padding: 8px 15px;margin:3px auto">企业端</div>
              <div @click="thousands1" style="background-color: #5E88C6;color: #ffffff;cursor: pointer;padding: 8px 15px;margin:3px auto">帮服端</div>
            </div> -->
          </div>
          <div @click="Huiqitong">
            <div class="about1">惠企通</div>
          </div>
          <div @click="huoduoduo">
            <div class="about2">通城货多多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="siterightposition">

    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img src="../img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>
<script>
import siteFooter from "@/components/siteFooter";
import bayWindow from "@/components/bayWindow.vue";
import custService from "@/view/custService/custService";
import request from "@/api/request";
export default {
  components: {
    'siteFooter': siteFooter,
    'custService': custService,
    'bayWindow': bayWindow,
  },
  data() {
    return {
      thousandsshow:false,
      islogin: 0,
      isactive: "is-active",
      isactive1: "",
      pageSize: 4,
      contentshow: "true",
      activitycontentshow: "",
      dialogTableVisible: false,
      seletedisplay: false,
      searchValue: "",
      baywindowsimage: "",
      baywindowsid: "",
      baywindowszd: "",
      currentStatus: [],
      baywindowstype: "",
      DeclarationInformation: {
        tableData: [],
        total: 0,
      },
      ActivityInformation: {
        tableData: [],
        total: 0,
      },
      options: [
        {
          value: "0",
          label: "综合",
        },
        {
          value: "1",
          label: "政策",
        },
        {
          value: "2",
          label: "图解",
        },
        {
          value: "3",
          label: "活动",
        },
        // {
        //   value: "4",
        //   label: "项目",
        // },
        // {
        //   value: "5",
        //   label: "数字地图",
        // },
        // {
        //   value: "6",
        //   label: "专业服务",
        // },
      ],
      value: "0",
    };
  },
  created() {
    this.checklogin();
    this.actuarialList();
    this.activityList();
    this.baywindows();
  },
  methods: {
    baywindows() {
      var that = this;
      request
        .get("/api/Policy/GetBay", {
        })
        .then((result) => {
          //console.log(result);
          // console.log(result.data.Data);
          if (result.data.Data.length > 0) {
            that.baywindowsid = result.data.Data[0].id
            that.baywindowszd = result.data.Data[0].zd
            that.baywindowstype = result.data.Data[0].type
            if (result.data.Data[0].imgUrl) {
              that.baywindowsimage = result.data.Data[0].imgUrl
            } else {
              that.baywindowsimage = "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/eqitong/baywindow.png"
            }
            // console.log(that.baywindowsid)
          }
        });
    },
    over(){
      this.thousandsshow=true;
    },
    enter(){
      this.thousandsshow=false;
    },
    navigationSelect() {
      this.$router.push("/personalcenter/0");
    },
    freeToEnjoy() {
      this.$router.push("/project/freeToEnjoy");
    },
    projectApply() {
      this.$router.push("/project/projectApply");
    },
    SpecializedAndNew() {
      const routeUrl = this.$router.resolve('/newspecial')
      window.open(routeUrl.href, '_blank')
    },
    changeTableSort(column) {
      // console.log(column);
      let sort = "";
      if(column.order==null){
        sort = "";
      }else if(column.order=="descending"){
        sort = column.order.substring(0, 4);
      }else if(column.order=="ascending"){
        sort = column.order.substring(0, 3);
      }
      this.DeclarationInformation.tableData = [];
      this.actuarialList(1, sort, column.prop);
    },
    changeActivityTableSort(column) {
      // console.log(column);
      let sort = "";
      if(column.order==null){
        sort = "";
      }else if(column.order=="descending"){
        sort = column.order.substring(0, 4);
      }else if(column.order=="ascending"){
        sort = column.order.substring(0, 3);
      }
      this.ActivityInformation.tableData = [];
      this.activityList(1, sort, column.prop);
    },
    signUp(_id) {
      // console.log(_id);
      this.$router.push("/PolicyCalculation/policySearchDetail/" + _id);
    },
    activityInfo(_id, _index) {
      // console.log(_id, _index);
      this.$router.push({
        path: "/activityInfo",
        query: {
          id: _id,
          index: _index,
        },
      });
    },
    checklogin() {
      if (localStorage.getItem("USERID")) {
        this.islogin = 1;
      } else {
        this.islogin = 0;
      }
    },
    async actuarialList(pageIndex, key, orderBy) {
      request
        .post("/api/Policy/Matchingnow", {
          pageSize: this.pageSize,
          pageIndex: pageIndex,
          key: key,
          orderBy: orderBy
        })
        .then((result) => {
          // console.log(result)
          result.data.Data.data.forEach((item) => {
            item.RemainderDay = item.RemainderDay + "天";
          });
          this.tableData = result.data.Data.data;
          this.DeclarationInformation.tableData = result.data.Data.data;
          this.DeclarationInformation.total = result.data.Data.total;
          // console.log(this.DeclarationInformation)
        });
    },
    async activityList(pageIndex, key, orderBy) {
      request
        .post("/api/Activity/GetActivityList", {
          pageSize: this.pageSize,
          pageIndex: pageIndex,
          key: key,
          orderBy: orderBy
        })
        .then((result) => {
          //代表成功获取的信息
          // if (result.data.Status != 1) {
          //   this.$router.push("/login");
          // }
          result.data.Data.data.forEach((item) => {
            if (Date.now() < Date.parse(item.begin_time)) {
              this.currentStatus.push(0);
            } else if (Date.now() > Date.parse(item.end_time)) {
              this.currentStatus.push(2);
            } else {
              this.currentStatus.push(1);
            }
            item.begin_time = item.begin_time.replace(/[a-zA-Z]/g, " ");
            item.end_time = item.end_time.replace(/[a-zA-Z]/g, " ");
            item.activitybegin_time = item.activitybegin_time.replace(
              /[a-zA-Z]/g,
              " "
            );
            item.activityend_time = item.activityend_time.replace(
              /[a-zA-Z]/g,
              " "
            );

            // item.activitybegin_time = this.formatDayTime(
            //   parseInt(item.activitybegin_time.match(/\d+/)[0])
            // );
          });
          this.ActivityInformation.tableData = result.data.Data.data;
          this.ActivityInformation.total = result.data.Data.total;
          // console.log(this.ActivityInformation.tableData)
        });
    },
    //每页条数切换
    handleCurrentChange(val) {
      this.actuarialList(val);
    },
    handleCurrentChangeactivity(val) {
      this.activityList(val);
    },
    Logout() {
      this.islogin = 0;
      localStorage.removeItem("USERID");
      localStorage.removeItem("USERTYPE");
      localStorage.removeItem("COMPANYNAME");
      localStorage.removeItem("QYID");
      localStorage.removeItem("TOKEN");
    },
    openDialog() {
      this.dialogTableVisible = true;
    },
    DeclarationInformations() {
      this.isactive = "is-active";
      this.isactive1 = "";
      this.contentshow = "true";
      this.activitycontentshow = "";
    },
    formatDayTime(v) {
      const date = new Date(v);
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    },
    ActivityInformations() {
      this.isactive = "";
      this.isactive1 = "is-active";
      this.contentshow = "";
      this.activitycontentshow = "true";
    },
    PolicyCalculation() {
      this.$router.push("/PolicyCalculation/policyIndex");
    },
    PolicySearch() {
      this.$router.push("/PolicyCalculation/PolicySearch");
    },
    PolicyInterpretation() {
      this.$router.push("/PolicyCalculation/policyinterpretation");
    },
    activity() {
      this.$router.push("/activity");
    },
    DirectDemand() {
      this.$router.push("/demandsadd");
    },
    DigitalMap() {
      this.$router.push("/declare/digitalMapOffer");
    },
    ProfessionalServices() {
      this.$router.push("/professionals");
    },
    login() {
      this.$router.push("/login");
    },
    Register() {
      this.$router.push("/register");
    },
    doSearch() {
      this.$router.push({
        name: "searchList",
        params: { status: parseInt(this.value), searchValue: this.searchValue },
      });
    },
    Huiqitong() {
      window.open('https://hqt.nantong.gov.cn/', '_blank')
    },
    thousands() {
      // window.open('https://q.qdqrqq.com/thousands/login.aspx', '_blank')
      const routeUrl = this.$router.resolve('/thousands')
      window.open(routeUrl.href, '_blank')
    },
    // thousands1() {
    //   window.open('https://q.qdqrqq.com/thousands_shilingdao/login.aspx', '_blank')
    // },
    huoduoduo() {
      window.open('https://hqt.nantong.gov.cn/webpages/product/productIndex.html', '_blank')
    }
  },
};
</script>
<style scoped>
@import url("../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
}

.header {
  background: url("https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/eqitong/index_bgimg.png") no-repeat;
  width: 100%;
  height: 430px;
  min-width: 1100px;
  background-size: 100% 100%;
}

/deep/ .el-dialog__body {
  padding: 0;
}

.personcenter {
  position: absolute;
  top: 61px;
  font-size: 16px;
  right: 200px;
  color: #ffffff;
  cursor: pointer;
}

.UserSpace li {
  width: 100px;
  float: right;
  text-align: left;
}

.UserSpace li a {
  width: 100px;
  display: inline-block;
  font-size: 0.9rem;
  padding: 5px 5px;
  color: #ffffff;
}

.UserSpace li:hover {
  color: #000000;
  /* background-color: #e6f4ff; */
}

.UserSpace li:hover a {
  color: #000000;
}

.UserSpacehover {
  color: #ffffff;
}

.UserSpacehover:hover ul {
  display: block;
}

.UserSpace {
  width: 112px;
  text-align: center;
  position: absolute;
  display: none;
}

.element {
  width: 1px;
  height: 1px;
}

.remen {
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
  min-width: 1100px;
}

.global-search {
  margin: 0.3125rem auto 0.75rem;
}

.global-search {
  width: 43.125rem;
  display: flex;
}

.global-search__input.el-input--suffix {
  font-size: 1rem;
}

.el-input {
  position: relative;
  font-size: 0.875rem;
  display: inline-block;
  width: 100%;
}

.el-input--suffix .el-input__inner {
  font-size: 0.9rem;
  border-radius: 1.25rem;
  height: 1.9rem;
  background-color: #fff;
  background-image: none;
  color: #606266;
  display: inline-block;
  line-height: 1.9rem;
  outline: none;
  width: 100%;
}

.el-input__inner::-webkit-input-placeholder{
  font-size:0.9rem !important;
}

.el-input__prefix {
  height: 1.9rem;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  cursor: pointer;
}

.el-select {
  display: inline-block;
  position: relative;
}

.el-select>.el-input {
  display: block;
}

.el-input {
  position: relative;
  font-size: 0.875rem;
  display: inline-block;
  width: 100%;
  outline: none;
  border: none;
}

.el-input:focus {
  outline: none;
  border: none;
}

.el-input__suffix {
  position: absolute;
  height: 100%;
  right: 0.3125rem;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}

.indexlist {
  margin-right: 20px;
}

.el-input__suffix-inner {
  pointer-events: all;
}

.el-select__caret.el-input__icon {
  line-height: 1.875rem;
}

.el-select .el-input .el-select__caret {
  color: #c0c4cc;
  font-size: 0.875rem;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
}

.el-input__suffix {
  right: 0;
  position: absolute;
  height: 100%;
  right: 0.3125rem;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}

.el-input__suffix-inner {
  pointer-events: all;
}

.el-select .el-input__inner {
  cursor: pointer;
}

.global-search__btn {
  background-color: rgb(0, 115, 255);
  cursor: pointer;
  width: 4.6875rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.25rem;
  position: absolute;
  margin-left: 660px;
  margin-top: -30px;
  z-index: 999;
}

.icon-search {
  width: 1.95rem;
  height: 1.95rem;
  opacity: 0.5;
  z-index: 999;
  color: #000000;
  background-size: 100%;
  background-image: url("../img/search.png");
}

.home-page__top__hotWords {
  display: flex;
  align-items: center;
  width: 43.125rem;
  justify-content: center;
  margin: 0 auto;
}

.hot-words-label {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #ffac0d;
}

.hot-words-label img {
  width: 15px;
  height: 15px;
}

.hot-words-list {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.hot-words-list div {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  padding: 0 0.625rem;
  border-radius: 0.625rem;
  text-align: center;
  margin-right: 0.75rem;
}

.home-page__nav {
  background: url("../img/index/nav-bg.0ce8c52.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  margin: 0 2.375rem 0 1.875rem;
  height: 11.25rem;
  position: relative;
  margin-bottom: 0.3125rem;
  z-index: 0;
}

.home-page__nav::before {
  background: url("../img/index/nav-l-bg.74a8299.png") no-repeat;
  background-size: 100% 75%;
  background-position: center;
  left: -1.8125rem;
}

.home-page__nav::before,
.home-page__nav::after {
  content: "";
  width: 16.125rem;
  position: absolute;
  height: 12.5rem;
  color: #fff;
  top: -0.6875rem;
}

.nav-lf {
  left: 0;
  width: 11.875rem;
  cursor: pointer;
  color: #fff;
  font-size: 1.25rem;
  position: absolute;
  height: 15.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: -3.25rem;
  z-index: 0;
}

.lf-icon-bg {
  background: url(../img/index/nav-l-icon.20b98c1.png) no-repeat;
  background-size: contain;
}

.lf-icon-bg,
.rt-icon-bg {
  width: 13.875rem;
  position: absolute;
  height: 8.3125rem;
  bottom: 2.5rem;
  z-index: 0;
  opacity: 0.5;
}

.nav-lf .lighter,
.nav-rt .lighter {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.nav-menus {
  display: flex;
  width: 45%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.menu-item {
  cursor: pointer;
  width: 4.375rem;
}

.circle {
  width: 2.375rem;
  height: 2.375rem;
  transform: scale(1);
  position: absolute;
  border-radius: 50%;
  z-index: -2;
}

.round {
  border-radius: 50%;
  width: 4.25rem;
  height: 4.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.menu-item__icon {
  width: 2.1875rem;
  height: 2.1875rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.menu-item__title {
  margin-top: 0.625rem;
}

.nav-rt {
  width: 11.875rem;
  cursor: pointer;
  color: #fff;
  font-size: 1.25rem;
  position: absolute;
  height: 15.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: -3.25rem;
  right: 0;
  z-index: 1;
}

.rt-icon-bg {
  background: url("../img/index/nav-r-icon.dad0f5f.png") no-repeat;
  background-size: contain;
  width: 13.875rem;
  position: absolute;
  height: 8.3125rem;
  bottom: 2.5rem;
  z-index: 0;
  opacity: 0.5;
}

.nav-rt .lighter {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.home-page__nav::after {
  background: url("../img/index/nav-r-bg.37f3fc1.png") no-repeat;
  background-size: 100% 75%;
  background-position: center;
  right: -2.0625rem;
  content: "";
  width: 16.125rem;
  position: absolute;
  height: 12.5rem;
  color: #fff;
  top: -0.6875rem;
}

.home-page__nav::before,
.home-page__nav::after {
  content: "";
  width: 16.125rem;
  position: absolute;
  height: 12.5rem;
  color: #fff;
  top: -0.6875rem;
}

.content-banner {
  width: 860px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -50px auto 0px auto;
  cursor: pointer;
}

.menu1 {
  background-image: url("../assets/PolicyCalculation/menu1.png");
}

.menu3 {
  background-image: url("../assets/PolicyCalculation/menu3.png");
}

.menu4 {
  background-image: url("../img/index/icon-8.24ca558.png");
}

.lf-head {
  display: flex;
  justify-content: space-between;
}

.home-page__menus {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 0.625rem;
}

.home-page__menus .menu-item {
  text-align: center;
  cursor: pointer;
  width: 24%;
}

.home-page__menus .menu-item__bg {
  width: 100%;
  height: 7.1875rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 0;
  position: relative;
}

.home-page__menus .menu-item:first-child .menu-item__icon-bg {
  opacity: 1;
}

.home-page__menus .menu-item__icon-bg {
  position: absolute;
  width: 100%;
  height: 6.875rem;
  background-position: right;
  background-size: 85% 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.home-page__menus .menu-item__title {
  font-size: 1.25rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
}

.home-page__menus .menu-item__title .title {
  margin-bottom: 0.5rem;
}

.home-page__menus .menu-item__title .sub {
  opacity: 0.8;
  font-size: 1rem;
}

.itembg1 {
  background-image: url("../img/index/menu-bg-1.82e260a.png");
}

.itembg1_1 {
  background-image: url("../img/index/menu-1-icon.5ec37b5.png");
}

.itembg2 {
  background-image: url("../img/index/menu-bg-2.7fe5727.png");
}

.itembg2_2 {
  background-image: url("../img/index/menu-2-icon.87f7c9e.png");
}

.itembg3 {
  background-image: url("../img/index/menu-bg-3.cc5ae06.png");
}

.itembg3_3 {
  background-image: url("../img/index/menu-3-icon.148478a.png");
}

.itembg4 {
  background-image: url("../img/index/menu-bg-4.eed3290.png");
}

.itembg4_4 {
  background-image: url("../img/index/menu-4-icon.9db7dd3.png");
}

.content-content {
  width: 960px;
  margin: 0px auto 0px auto;
}

.lf {
  width: 1020px;
  text-align: center;
  margin: 10px auto 30px auto;
}

.is-active {
  border-bottom: 3px solid #2261c9;
}

.le_content {}

.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}

.siterightposition {
  position: fixed;
  top: 40%;
  right: 10px;
}

.lf-content-image .about,
.about1,
.about2,
.about3 {
  width: 160px;
  height: 80px;
  line-height: 80px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
}

.lf-content-image .about {
  background-color: #5E88C6;
}

.lf-content-image .about1 {
  background-color: #64CCE5;
}

.lf-content-image .about2 {
  background-color: #367BF0;
}
.lf-content-image .about3 {
  background-color: #03B8B6;
}

.lf-content-image {
  margin-top: 10px;
}

.lf-content-image div {
  margin: 10px auto;
}

.lf-content-image div img {
  width: 160px;
  height: 80px;
  cursor: pointer;
}

.digitaDetails {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*3表示只显示3行*/
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
</style>