<template>
    <div class="sq_show">
        <div id="sq_show_xinxi"></div>
        <div class="zc_show_ziliao">
            <div class="zc_show_title">
                {{ ClaimDetails.message.title }}
            </div>
            <div>诉求企业：{{ ClaimDetails.message.qyname }}</div>
            <div>提交时间：{{ ClaimDetails.message.posttime }}</div>
            <div>提交人员：{{ ClaimDetails.message.name }}</div>
            <div>诉求内容：{{ ClaimDetails.message.scontent }}</div>

        </div>
        <!-- <div class="zc_show_center">
            {{ ClaimDetails.message.scontent }}
        </div> -->
        <div style="width: 100%; margin: 10px auto;">
            <div style="text-align: center; color: gray; border: 1px dashed gray;"></div>
        </div>
        <div style="width: 90%;padding: 10px 25px;">
            <div class="aui-timeLine b-line">
                <ul class="aui-timeLine-content">
                    <span id="chuliliucheng"></span>
                    <li class="aui-timeLine-content-item" v-for="(item, index) in ClaimDetails.flow" :key="index">
                        <em class="aui-timeLine-content-icon"></em>
                        <p style="font-size:18px;">{{ item.status }}</p>
                        <p style="margin-top: 10px;font-size:13px;">{{ item.posttime }}</p>
                        <p style="margin-top: 10px;font-size:13px;">处理人：{{ item.name }}</p>
                        <p style="margin-top: 10px;font-size:13px;">
                            回复内容：{{ item.content }}
                        </p>
                    </li>
                </ul>
            </div>
            <div v-if="ClaimDetails.flow.length>0">
                <div style="margin-top: 30px;margin-left: 20px;"
                    v-if="ClaimDetails.flow[ClaimDetails.flow.length - 1].status == '已回复'">
                    <el-button type="primary" style="padding: 12px 32px;" @click="dialogVisible = true">评价</el-button>
                </div>
            </div>
            <el-dialog title="评价" :visible.sync="dialogVisible" width="30%" @close="handleClose">
                <el-rate v-model="rating" :max="5" show-score text-template="{value}分"
                    style="margin-bottom: 20px"></el-rate>
                <el-input v-model="comment" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入您的评价"
                    maxlength="100" show-word-limit></el-input>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false" style="padding: 12px 32px;">取 消</el-button>
                    <el-button type="primary" @click="submitRating" style="padding: 12px 32px;">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <input type="hidden" id="himglist" />
        <input type="hidden" id="himglistfilename" />
    </div>
</template>
<script>
import request from "@/api/request";
export default {
    name: "demandsAdd",
    props: {
        id: String,
        required: true
    },
    data() {
        return {
            ClaimDetails: {
                message: {},
                flow: [],
            },
            cData: [],
            dialogVisible: false,
            rating: 0,
            comment: ''
        }
    },
    created() {
        this.getflowlist();
    },
    methods: {
        handleClose() {
            this.rating = 0;
            this.comment = '';
        },
        submitRating() {
            // console.log(this.rating)
            this.$confirm("确认评价？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "info",
            })
                .then(() => {
                    request.post("/api/Demands/ApplyRemark", {
                        ID: this.$props.id,
                        remark: this.comment,
                        evaluate: this.rating,
                    })
                        .then((res) => {
                            console.log(res);
                            if (res.data.Status == 1) {
                                this.$message({
                                    type: "success",
                                    message: "评价成功!",
                                });
                                this.dialogVisible = false; // 关闭对话框
                                this.ClaimDetails.message = {};
                                this.ClaimDetails.flow = [];
                                this.getflowlist();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "评价失败!",
                                });
                                this.dialogVisible = false; // 关闭对话框
                            }
                            this.rating = 0; // 重置评分
                            this.comment = ""; // 重置评价
                        });
                })
                .catch(() => {
                    this.rating = 0; // 重置评分
                    this.comment = ""; // 重置评价
                });
        },
        drawChart() {
            // console.log(this.cData)
        },
        getflowlist() {
            let url = "/api/Demands/getDemandsDetails";
            let reqObj = {
                ID: this.$props.id,
            };
            request.post(url, reqObj).then((res) => {
                // console.log(res);
                if (res.data.Status == 1) {
                    this.ClaimDetails.message = res.data.Data.suqiu[0];
                    // console.log(this.ClaimDetails.message);
                    this.ClaimDetails.flow = [...res.data.Data.huifu.map(item => {
                        return {
                            status: item.status,
                            name: item.name,
                            posttime: item.posttime,
                            content: item.remark
                        }
                    })];
                }
            });
        }
    },
}
</script>
<style scoped>
@import url('../../../public/layui/css/layui.css');

* {
    margin: 0;
    padding: 0;
    text-align: left;
}

.sq_show {
    margin: 0px auto;
    border-radius: 10px;
    overflow: auto;
}

.zc_show_title {
    width: 95%;
    font-size: 23px;
    font-weight: bold;
    padding: 10px 0px;
    color: #000000;
}

.zc_show_ziliao {
    width: 90%;
    height: auto;
    line-height: 28px;
    padding: 10px 35px;
    font-size: 15px;
    color: gray;
}

.zc_show_center {
    width: 90%;
    height: auto;
    padding: 10px 35px;
    font-size: 18px;
    color: gray;
    text-indent: 2rem;
    text-align: left;
}

.zc_show_fujian {
    width: 100%;
    height: auto;
    padding: 10px 5px;
    font-size: 15px;
    color: gray;
}

.zc_show_fujian img {
    margin: 2px 2px;
}

.zc_show_fujian1 {
    font-size: 15px;
    color: black;
}

.zc_show_fujian2 {
    margin-top: 10px;
}

.aui-timeLine {
    background-color: #fff;
    font-size: 15px;
    color: #6e6e6e;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.aui-timeLine-content {
    margin-left: 16px;
    border-left: 1px solid #e4e5e9;
}

.aui-timeLine-content-item {
    padding: 16px 12px 16px 0;
    margin-left: 16px;
    position: relative;
    counter-increment: my-custom-counter -1;
}

.aui-timeLine-content-item:last-child {
    margin-top: 16px;
    padding-top: 0;
    color: #ff8785;
}

.aui-timeLine-content-item:last-child:before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: -24px;
    background-color: #fbbfbf;
    border-radius: 99px;
}

.aui-timeLine-content-icon {
    width: 8px;
    height: 8px;
    border-radius: 99px;
    background-color: #e4e5e9;
    content: "";
    position: absolute;
    z-index: 1;
    left: -16px;
    display: block;
    top: 19px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}

.aui-timeLine-content-item:last-child>.aui-timeLine-content-icon {
    top: 3px;
}

.aui-timeLine-content-item:last-child>.aui-timeLine-content-icon {
    background-color: #f23030;
    width: 10px;
    height: 10px;
}

.isjiejue {
    /*background-color:#2459A5;*/
    background-color: lightgray;
    margin: 0px 25px;
    color: white;
    padding: 5px 15px;
    border-radius: 30px;
}

.weijiejue {
    background-color: lightgray;
    margin: 0px 25px;
    color: white;
    padding: 5px 15px;
    border-radius: 30px;
}

.jiejue {
    margin: 25px auto 0px auto;
    text-align: center;
}

/*.suqiupingjia_zhezhao {
            display: block;*/
/*position: fixed;
              width: 100%;
              height: 100vh;
              top: 0%;
              left: 0%;
              background-color: lightgray;
              opacity: .9;
              z-index: 100;
              overflow-x:hidden*/
/*}*/
.suqiupingjia {
    display: none;
    position: relative;
    top: 40%;
    background-color: white;
    width: 90%;
    opacity: .9;
    height: 150px;
    margin: 0px auto;
    border-radius: 10px;
    z-index: 99;
    overflow: auto;
}

ul,
li {
    list-style: none
}

a {
    text-decoration: none;
    color: #666
}

a:hover {
    text-decoration: none
}

.icon {
    width: 30px;
    height: 30px
}

.hover {
    background: #0f6c8d
}

.clickMask {
    position: absolute;
    left: 0;
    top: 0;
    right: -1px;
    bottom: 0;
    z-index: 999;
    display: none
}

.alarm {
    position: fixed;
    top: 240px;
    margin-left: 627px;
    font-size: 26px;
    color: #f90
}

.clearFix:after {
    content: '';
    display: block;
    clear: both
}

i {
    font-style: normal
}

#active {
    font-size: 12px;
    color: #289cd5;
    font-weight: 700
}

.course_main {
    width: 100%;
    height: 100%
}

.top {
    width: 100%;
    height: 90px;
    background: #fff;
    color: #000
}

.top_nr {
    width: 1200px;
    margin: 0 auto
}

.top_left {
    height: 90px;
    float: left
}

.top_left i {
    width: 180px;
    height: 90px;
    display: block;
    float: left;
}

.top_left h1 {
    line-height: 100px;
    display: block;
    float: left;
    font-size: 28px;
}

.top_right {
    height: 90px;
    float: right;
    line-height: 90px;
    padding-right: 10px;
}

.top_right a {
    padding: 0 10px;
    color: #000;
    font-size: 14px;
    display: block;
    float: left;
}

.top_right a span {
    padding-right: 5px;
    display: block;
    float: left;
}

.top_right a i {
    color: #f00;
    padding: 0 5px;
    display: block;
    float: left;
}

.top_right a b {
    display: block;
    float: left;
}

.top_right a img {
    margin-top: 30px;
    display: block;
    float: left;
}

.nav {
    width: 100%;
    height: 50px;
    background: #389fc3;
    margin-top: 15px;
}

.nav_nr {
    width: 1200px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    font-size: 14px;
}

.nav_nr ul {
    display: block;
    margin: 0;
}

.nav_nr ul li {
    height: 50px;
    display: block;
    float: left;
}

.nav_nr ul li a {
    color: #fff;
    display: block;
    height: 50px;
    float: left;
    padding: 0 20px;
}

.nav_nr ul li a:hover {
    text-decoration: none;
}

.nav_nr i {
    color: #fff;
    display: block;
    float: left;
}

.fy {
    width: 100%;
    height: 50px;
    margin-left: 15px;
    margin-top: 10px;
    float: left;
}

.easyPieChart {
    position: relative;
    text-align: center;
}

.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.foot {
    width: 100%;
    height: 50px;
    clear: both;
}

.test_main {
    width: 1320px;
    margin: 15px auto 0;
    overflow: hidden;
}

.nr_nav {
    width: 120px;
    height: 1px;
    float: left;
}

.nr_nav1 {
    width: 120px;
    position: fixed;
    top: 16px;
    z-index: 1000;
    overflow: hidden;
}

.ceping_title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: bold;
    background-color: #FFF;
    text-align: left;
}

.ceping_students {
    text-align: left;
    line-height: 30px;
}

.ceping_students span {
    display: block;
    border-top: 1px solid #F5F5F5;
}

.nr_left {
    width: 900px;
    height: 100%;
    float: left;
    position: relative;
}

.test {
    width: 100%;
    border: 1px solid #e4e4e4;
    text-align: left;
    float: left;
}

.test_title {
    width: 900px;
    height: 45px;
    line-height: 45px;
    background-color: #f7f7f7;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.title_icon {
    float: left;
    height: 45px !important;
}

.first_title {
    float: left;
}

.test_title p {
    padding: 0 20px;
    display: block;
    float: left;
}

.test_time {
    color: #389FC3;
    line-height: 45px;
}

.test_time i {
    font-size: 20px;
    height: 45px;
    line-height: 40px;
    display: block;
    float: left
}

.test_time b {
    font-size: 16px;
    height: 45px;
    display: block;
    margin-left: 10px;
}

.test_dtk {
    line-height: 45px;
}

.test_dtk i {
    font-size: 22px;
    height: 45px;
    line-height: 45px;
    display: block;
    float: left;
    color: #389fc3;
}

.test_dtk span {
    font-size: 14px;
    height: 45px;
    display: block;
    float: left;
    margin-left: 10px;
}

.test_title font {
    width: 80px;
    height: 45px;
    display: block;
    float: right;
    text-align: center;
}

.test_title font a {
    background: #389fc3;
    border: none;
    display: block;
    width: 80px;
    height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.test_title font a:active {
    background: #79aef0;
}

.test_content_title {
    background: #F2F6F9;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-family: é»‘ä½“;
}

.test_content {
    width: 100%;
    height: auto;
}

.test_content_title h2 {
    padding: 0 30px;
    font-size: 16px;
    font-weight: normal;
    display: block;
    float: left;
}

.test_content_title p {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    background: #389fc3;
    color: #fff;
    border-radius: 30px;
    display: block;
    float: left;
    margin-top: 5px;
    margin-left: 18px;
}

.test_second_title {
    padding: 0 30px;
    font-size: 16px;
    font-weight: normal;
    display: block;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #f1efef;
    color: #389Fc3;
}

.test_content_nr {
    width: 100%;
}

.test_content_nr>ul>li {
    width: 100%;
    border-bottom: 5px solid #efefef;
    padding-top: 10px;
}

.test_content_nr_tt {
    width: 85%;
    height: auto;
    line-height: 32px;
    margin: 0 auto;
    border-bottom: 1px solid #e4e4e4;
}

.test_content_nr_tt i {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    display: block;
    float: left;
    background: #5d9cec;
    border-radius: 50%;
    margin-left: -50px;
    color: #fff;
    margin-top: 8px;
    font-size: 16px;
}

.test_content_nr_tt span {
    padding: 0 8px;
}

.test_content_nr_tt font {
    font-size: 14px
}

.test_content_nr_tt b {
    display: block;
    float: right;
    margin-right: -50px;
    color: #f48c27;
    font-size: 20px;
}

.test_content_nr_main {
    width: 85%;
    margin: 0 auto;
    padding: 10px 0;
    height: auto;
}

.option {
    line-height: 32px;
    display: block;
    background: #fff;
    color: #666;
}

.option:hover {
    background: #e4e4e4;
}

.option input {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    margin: 7px 10px 0 0;
}

.option label {
    height: auto;
    display: block;
}

.test_more_nr_main {
    width: 85%;
    margin: 0 auto;
    padding: 10px 0;
}

.multiple {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: block;
    background: #fff;
    margin-bottom: 3px;
}

.multiple:hover {
    background: #e4e4e4;
}

.multiple dd input {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    margin: 10px 10px 0 0;
}

.multiple dd label {
    width: 725px;
    height: 40px;
    display: block;
    float: left;
}

.test_click {
    background: #7aaff3;
}

.test_click a {
    color: #fff;
}

.nr_right {
    width: 280px;
    height: 100%;
    float: right;
}

.nr_rt_main {
    width: 100%;
    height: auto;
}

.rt_nr1 {
    width: 280px;
    position: fixed;
    top: 15px;
    z-index: 1000;
}

.rt_nr1_title {
    width: 280px;
    height: 45px;
    line-height: 45px;
    background: #f3f3f3;
}

.rt_nr1_title h1 {
    width: 50%;
    height: 45px;
    background: #389fc3;
    text-align: center;
    font-size: 14px;
    display: block;
    float: left;
    color: #fff;
}

.rt_nr1_title h1 i {
    padding: 0 5px;
    font-size: 14px;
    font-weight: normal;
}

.rt_nr1_title p {
    width: 130px;
    height: 43px;
    padding-right: 8px;
    border: 1px solid #e4e4e4;
    border-left: 0;
    display: block;
    float: right;
}

.rt_nr1_title p i {
    margin-left: 10px;
}

.rt_content {
    width: 278px;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-top: 0;
}

.rt_content_tt {
    width: 95%;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    border-bottom: 1px solid #e4e4e4;
}

.rt_content_tt h2 {
    width: 100%;
    font-size: 14px;
    display: inline-block;
    text-align: left;
}

.rt_content_tt p {
    width: 100px;
    display: inline-block;
}

.rt_content_tf {
    width: 95%;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    text-align: left;
}

.answerSheet ul {
    padding: 10px;
    text-align: left;
}

.answerSheet li {
    display: inline-block;
    margin-bottom: 5px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #e4e4e4;
    margin-right: 5px;
}

.answerSheet li a {
    display: block;
}

.answerSheet li:hover {
    color: #389fc3;
    border-color: #389fc3;
}

.rt_more {
    width: 278px;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-top: 0;
}

.rt_more_tt {
    width: 95%;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    border-bottom: 1px solid #e4e4e4;
}

.rt_more_tt h2 {
    width: 150px;
    font-size: 14px;
    display: inline-block;
}

.rt_more_tt p {
    width: 100px;
    display: inline-block;
}

.rt_more_nr ul {
    padding: 10px;
    text-align: left;
}

.rt_more_nr ul li {
    display: inline-block;
    margin: 5px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #e4e4e4;
}

.star_content {
    margin: 5px 0;
    padding: 5px 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    background: #F5F5F5;
}

.textarea textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 80px;
    border: none;
    font-size: 14px;
    outline: none;
    padding: 10px 0;
}

.description {
    color: #47cbff;
}

.hasBeenAnswer {
    background: #5d9cec;
    color: #fff;
}

.item {
    width: 150px;
    height: 150px;
    float: left;
    position: relative;
    margin: 20px;
    margin-left: 0;
}

.addImg {
    width: 150px;
    height: 150px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
}

.preview,
.preBlock {
    position: absolute;
    display: block;
    width: 150px;
    height: 150px;
    left: 0;
    top: 0;
}

.delete {
    width: 30px;
    position: absolute;
    right: -30px;
    top: -15px;
    cursor: pointer;
    display: none;
}

.preBlock img {
    display: block;
    width: 150px;
    height: 150px;
}

.upload_input {
    display: block;
    width: 0;
    height: 0;
    -webkit-opacity: 0.5;
    -moz-opacity: 0.0;
    -khtml-opacity: 0.0;
    opacity: .0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

.image_title {
    font-size: 16px;
}

.starability-slot {
    display: block;
    position: relative;
    width: 150px;
    min-height: 35px;
    padding: 0;
    border: none
}

.starability-slot>input {
    position: absolute;
    margin-right: -100%;
    opacity: 0
}

.starability-slot>input:checked~label,
.starability-slot>input:hover~label {
    background-position: 0 -30px;
    transition: background-position .7s
}

.starability-slot>label {
    position: relative;
    display: inline-block;
    float: right;
    width: 30px;
    height: 30px;
    color: transparent;
    cursor: pointer;
    background-image: url(../../assets/PolicyCalculation/icons.png);
    background-repeat: no-repeat
}

.starability-title,
.starability-container {
    float: left;
}

.starability-title {
    height: 35px;
    line-height: 35px;
    margin: 0 10px;
    font-size: 16px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.pingjiahuoquxiao {
    width: 100%;
    text-align: center;
    margin-top: 15px;
}

.pingjiahuoquxiao span {
    background-color: #2459A5;
    padding: 6px 30px;
    margin: 10px 20px;
}

.jixuhuifu {
    width: 100%;
    height: 300px;
    margin: 0px auto;
    background-color: #f5f5f5;
}






.center_list1 {
    width: 95%;
    height: auto;
    margin: 0px auto;
}

.listss1 {
    margin-top: 10px;
}

.drop {
    width: 65%;
    height: 35px;
    line-height: 35px;
    margin: 5px auto 0px auto;
    font-size: 15px;
    float: right;
    border: none;
    outline: none;
    text-align: right;
    background-color: white;
    color: gray;
}

.txt {
    display: inline;
    margin-left: 30px;
}

input {
    width: 65%;
    height: 35px;
    margin: 10px auto 20px auto;
    font-size: 15px;
    margin-top: 15px;
    border: none;
    float: right;
    outline: none;
    text-align: right;
    background-color: white;
}

textarea {
    width: 100%;
    height: 100px;
    font-size: 15px;
    border: none;
    outline: none;
    border: 2px solid lightgray;
    text-indent: 1rem;
    margin-top: -20px;
}

.title1 {
    width: 90px;
    float: left;
    font-family: 微软雅黑;
    font-size: 15px;
    color: black;
    line-height: 60px;
}

.title {
    width: 90px;
    float: left;
    font-family: 微软雅黑;
    font-size: 15px;
    color: black;
    line-height: 60px;
}

.suqiu_fill {
    width: 90%;
    height: auto;
    line-height: 30px;
    margin: 15px auto 10px auto;
    padding: 10px 5px 20px 15px;
    background-color: white;
    border-radius: 10px;
}

.up-btn {
    font-size: 15px;
    padding: 3px 8px;
    background-color: #2459A5;
    border-radius: 30px;
    color: #FFF;
}

.btn {
    width: 130px;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px;
    margin-top: 50px;
    background-color: #2459A5;
    text-align: center;
    font-size: 15px;
    color: white;
    border-radius: 30px;
    text-align: center;
    display: inline-block;
}

.jixuhuifu {
    display: none;
    height: auto;
}

.loadinging {
    display: inline-block;
}

.loading {
    width: 80px;
    height: 8px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 100px;
    position: relative;
    background: lightblue;
    overflow: hidden;
}

.loading span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background: lightgreen;
    -webkit-animation: changePosition 4s linear infinite;
}

@-webkit-keyframes changePosition {
    0% {
        -webkit-transform: translate(-150px);
    }

    50% {
        -webkit-transform: translate(0);
    }

    100% {
        -webkit-transform: translate(150px);
    }
}
</style>