<template>
  <div>
    <div class="banner">
      <!-- <img src="../../../images/demands-banner.jpg" width="100%" height="300" alt="" srcset=""> -->
      <div class="box">
        <span class="submenu-banner-title"
          ><em class="large">政策</em>计算</span
        >
        <span class="line"></span>
        <!-- <span class="submenu-banner-entitle">Policies Calculation</span> -->
      </div>
      <div class="tag">
        <div
          class="submenu-item"
          v-for="(item, index) in arrayitem"
          :key="index"
          @click="PolicyActuarial(item.url, index)"
        >
          <div class="round" :style="item.bgcolor">
            <div
              class="circle"
              :class="{ animmate: index == isactive }"
              :style="item.bgcolor"
            ></div>
            <div class="submenu-item__icon" :class="item.icon"></div>
          </div>
          <div class="submenu-item__title" style="color: #ffffff">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="shadow"></div>
  </div>
</template>
<script>
export default {
  name: "pageBanner",
  components: {},
  data() {
    const arrayitem = [
      {
        url: "/PolicyCalculation/policyIndex",
        icon: "menu1",
        bgcolor: "background-color:rgb(246, 175, 63);",
        name: "政策精算",
      },
      {
        url: "/PolicyCalculation/declareToday",
        icon: "menu2",
        bgcolor: "background-color:rgb(163, 213, 95);",
        name: "今日申报",
      },
      {
        url: "/PolicyCalculation/PolicySearch",
        icon: "menu3",
        bgcolor: "background-color:rgb(0, 191, 215);",
        name: "政策检索",
      },
      {
        url: "/PolicyCalculation/policyInterpretation",
        icon: "menu4",
        bgcolor: "background-color:rgb(103, 201, 187);",
        name: "政策解读",
      }, { url: '/PolicyCalculation/PolicySubscription', icon: 'menu5', bgcolor: 'background-color:#00A0E9;', name: '政策订阅' }
    ];
    return {
      arrayitem: arrayitem,
      isactive: 0,
    };
  },
  created() {
    this.PageRouting = window.location.href.substring(
      window.location.href.lastIndexOf(
        "/",
        window.location.href.lastIndexOf("/") - 1
      )
    );
    this.isactive = this.arrayitem.findIndex(
      (value) => value.url == this.PageRouting
    );
  },
  methods: {
    PolicyActuarial(url, index) {
      this.isactive = index;
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
@import url("../../../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
}

a,
li {
  color: #000;
  list-style: none;
  text-decoration: none;
}

.animmate {
  transform: scale(1.2);
  opacity: 0.5;
}

.banner {
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../images/policycalculation-banner.jpg");
}

.box {
  display: flex;
  flex-direction: column;
  width: 64.375rem;
  margin: 0 auto;
}

.submenu-banner-title {
  font-size: 2.25rem;
  font-weight: bold;
  color: #fff;
  text-align: left;
  text-shadow: 0 0.3125rem 0.25rem rgba(0, 54, 114, 0.6);
}

.large {
  font-style: normal;
  font-size: 3.375rem;
  margin-right: 0.625rem;
}

.line {
  width: 18.125rem;
  height: 0.0625rem;
  background-color: #fff;
  opacity: 0.5;
  margin: 0.3125rem 0 0.625rem;
}

.submenu-banner-entitle {
  font-size: 0.875rem;
  color: #fff;
  text-align: left;
  letter-spacing: 0.125rem;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 62vh;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  background: linear-gradient(180deg, rgba(110, 150, 216, 0.5) 0%, #fff 75%);
  overflow: hidden;
  overflow-y: auto;
  z-index: -1;
}

.submenu-item {
  font-size: 1.125rem;
  margin: 0 4.375rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.circle {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
  position: absolute;
  border-radius: 50%;
  z-index: -2;
}

.submenu-item__icon {
  width: 1.875rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;
}

.menu1 {
  background-image: url("../../../assets/PolicyCalculation/menu1.png");
}

.menu2 {
  background-image: url("../../../assets/PolicyCalculation/menu2.png");
}

.menu3 {
  background-image: url("../../../assets/PolicyCalculation/menu3.png");
}

.menu4 {
  background-image: url("../../../assets/PolicyCalculation/menu4.png");
}

.menu5 {
  background-image: url("../../../assets/PolicyCalculation/menu5.png");
}

.submenu-item {
  font-size: 1.125rem;
  margin: 0 4.375rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submenu-item__title {
  margin-top: 0.625rem;
  text-shadow: 0 0.1875rem 0.4375rem rgba(0, 0, 0, 0.24);
}

.tag {
  width: 64.375rem;
  display: flex;
  position: relative;
  margin: 71px auto 0px auto;
  text-align: center;
  justify-content: center;
}
</style>